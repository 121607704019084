import React from "react";
import moment from "moment";

class ScrollBox extends React.Component {
  render() {
    const { editions, articles, providers } = this.props;

    const articleCheck = (article) => {
      // const article = articles.find(
      //   (article) => article.edition_id === edition.id
      // );
        return (
          <a href={"https://www.irf.uk/" + "/editions/" + editions.find(edition => edition.id == article.edition_id).number + `?article_id=${article.id}`} className="white_links_no">
            <p style={{paddingRight: '10px', marginBottom: '5px'}}>{article.title.replace(/<[^>]*>?/gm, '')}</p>
          </a>
        );
      
    };

    const draftCheck = (edition) => {
      if (edition.draft === true) {
        return " - DRAFT";
      }
    };

    return (
      <div className="scroll-box-container editions-scroll-box-container">
        <div id="table-wrapper">
          <div id="table-scroll">

            <div className="edition-search-mobile">
              {articles.map((article) => (
                <div className="edition-search-card" style={{textAlign: 'left'}}>
                  {articleCheck(article)}
                  <div style={{marginBottom: '5px'}}>
                    Provider: <a href={"https://www.irf.uk/" + "/providers/" + providers.find(provider => article.provider_id == provider.id) ? providers.find(provider => article.provider_id == provider.id).id : "" } className="white_links_no">
                      {providers.find(provider => article.provider_id == provider.id) ? providers.find(provider => article.provider_id == provider.id).title : ""}
                    </a>
                  </div>
                  {moment(`${editions.find(edition => edition.id == article.edition_id).date}`).format("Do MMM YY")}
                  {/* {editions.find(edition => edition.id == article.edition_id).number} */}
                  {/* {draftCheck(editions.find(edition => edition.id == article.edition_id).number)} */}
                </div>
              ))}
            </div>

            <table className="edition-search-web">
              <thead>
                <tr style={{textAlign: "left"}}>
                  <th style={{width: "15%"}}>
                    <span className="text fifth">Date</span>
                  </th>
                  <th style={{width: "50%"}}>
                    <span className="text three-fifths">Article</span>
                  </th>
                  <th style={{width: "35%"}}>
                    <span className="text fifth">Provider</span>
                  </th>
                </tr>
              </thead>
              {console.log(articles)}
              <tbody>
                {articles.map((article) => (
                // {article.edition_id === edition.id}
                  <tr key={editions.find(edition => edition.id == article.edition_id).number} style={{textAlign: "left"}}>
                    <td style={{width: "15%"}}>{moment(`${editions.find(edition => edition.id == article.edition_id).date}`).format("Do MMM YY")}</td>
                    <td style={{width: "50%"}}>{articleCheck(article)}</td>
                    <td style={{width: "35%"}}>
                      {/* {editions.find(edition => edition.id == article.edition_id).number} */}
                      {/* {draftCheck(editions.find(edition => edition.id == article.edition_id).number)} */}
                      <a href={"https://www.irf.uk/" + "/providers/" + providers.find(provider => article.provider_id == provider.id) ? providers.find(provider => article.provider_id == provider.id).id : "" } className="white_links_no">
                        {providers.find(provider => article.provider_id == provider.id) ? providers.find(provider => article.provider_id == provider.id).title : ""}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ScrollBox;
