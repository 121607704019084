import readXlsxFile from 'read-excel-file'

const excelParser = () => {
  const stocks_input = document.querySelector('.stocks_input')
  let data = ""
  const columnName = document.querySelector('#column-input')
  // File.
  if(stocks_input) {
    const input = document.getElementById('input')
    input.addEventListener('change', () => {
      stocks_input.value = ""
      data = ""
      readXlsxFile(input.files[0]).then((rows) => {
        const index = rows[0].indexOf(columnName.value)
        console.log(rows[0])
        if(false) {
          alert("Please enter a valid column title and upload the file again")
          input.value = ""
        } else {
          stocks_input.value = ""
          rows.forEach((row) => { 
            data += `${row[0]},` 
          })
          stocks_input.value = data.split(",").slice(0, 20).toString()
          input.value = ""
          console.log(stocks_input)
        }
      })
    })
  }
}

export { excelParser }