const filter = () => {
  // const providers = document.getElementById('providers');
  // const filters = document.querySelectorAll('.option-value')
  const dropdowns = document.querySelectorAll('.drop-select')
  
  // console.log(filters[0].innerHTML)

  dropdowns.forEach(function(item) {
    // console.log(item.innerHTML)
    item.addEventListener('change', function() {

      const selectedInput = item.options[item.selected].text
    })
  // providers.innerHTML = innerHTML;
  })
};

export { filter };


