import React from "react";
import moment from "moment";

class ScrollBoxOnLoad extends React.Component {
  render() {
    const { editions, articles } = this.props;

    const articleCheck = (edition) => {
      const article = articles.find(
        (article) => article.edition_id === edition.id
      );

      if (article) {
        return (
          <a href={"/editions/" + edition.number}>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="book-open"
              className="svg-inline--fa fa-book-open fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"
              ></path>
            </svg>
          </a>
        );
      } else {
        return (
          <a href={edition.link} target="_blank">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="book-open"
              className="svg-inline--fa fa-book-open fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"
              ></path>
            </svg>
          </a>
        );
      }
    };

    const draftCheck = (edition) => {
      if (edition.draft === true) {
        return " - DRAFT";
      }
    };

    return (
      <div className="scroll-box-container editions-scroll-box-container">
        <div id="table-wrapper">
          <div id="table-scroll">
            <table>
              <thead>
                <tr>
                  <th>
                    <span className="text">Edition</span>
                  </th>
                  <th>
                    <span className="text">Date</span>
                  </th>
                  <th>
                    <span className="text">Link</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {editions.map((edition) => (
                  <tr key={edition.number}>
                    <td>
                      {edition.number}
                      {draftCheck(edition)}
                    </td>
                    <td>{moment(`${edition.date}`).format("Do MMM YY")}</td>
                    <td>{articleCheck(edition)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ScrollBoxOnLoad;
