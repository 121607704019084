const editionsSearch = () => {
    const editionContainer = document.querySelector(".edition-container")
    const form = document.querySelector(".title-title")
    const input = document.querySelector(".search-field")
    const submitBtn = document.querySelector(".edition-search-button")

    if(form) {
        form.addEventListener("submit", (event) => {
            event.preventDefault();
            
            const url = `${form.action}?search_cut=${input.value}&commit=Search`

            editionContainer.innerHTML = `<div style="height: 100vh;">
            <div style="margin: 60px auto 0 auto; width: 100vw; text-align: center;">
                <div class="spinner-border" role="status" style="width: 8vw; height: 8vw;">
                    <span class="visually-hidden"></span>
                </div>
            </div>
            <p style="margin: 20px auto 0 auto; width: 20vw; text-align: center;">LOADING ARTICLES</p>
            </div>`

            fetch(url, {headers: { "Accept": "text/plain" }} )
            .then(response => response.text())
            .then((data) => {
                editionContainer.innerHTML = data
                submitBtn.disabled = false
                form.reset()
            })
        });
    }
};

export { editionsSearch }