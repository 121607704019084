import React from 'react';

const ProviderOverview  = ({provider, comps, sectors}) => {
    return(
        <div className="parent-container">
            <div className="modal-comp-title">
                <h2 >{provider.title} Companies</h2>
                <hr className="red-divider red-divider-modal"/>
            </div>
            <div className="modal-header-subs">
                <table id="modal-table">
                    <thead>
                        <tr>
                            <th><span className="text">Company</span></th>
                            <th><span className="text">Ticker</span></th>
                            <th><span className="text">Sector</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {comps.map((comp) => {
                            
                            <tr>
                                <td><a href={'/directory?search_directory=' + comp.title.split(" ").first}>{comp.title}</a></td>
                                <td>{comp.ticker}</td>
                                <td>{sectors.find((e) => e.id === comp.sector_id).title}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ProviderOverview;