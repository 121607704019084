var counter = 0
let gallery = ["page 1.jpg","page 2.jpg", "page 3.jpg", "page 4.jpg", "page 5.jpg", "page 6.jpg", "page 7.jpg", "page 8.jpg", "page 9.jpg", "page 10.jpg", "page 11.jpg", "page 12.jpg", "page 13.jpg", "page 14.jpg", "page 15.jpg", "page 16.jpg"]

const PageTurn = () => {
    const cutContainer = document.querySelector(".the-cut-container")
    if (cutContainer) {
        document.getElementById("chevleft").addEventListener("click", () => {
            counter--;
            if (counter <= -1) {
                counter = 15;
            }
            document.getElementById('cut-page').src = `assets/edition/${gallery[counter]}`;
        });

        document.getElementById("chevright").addEventListener("click", () => {
            counter++;
            if (counter >= gallery.length) {
            counter = 0;
            }
            document.getElementById('cut-page').src = `assets/edition/${gallery[counter]}`;
        });   
    }
}

export { PageTurn };